.TopArtist {
  background-color: #3b00e2;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.initial-animate-screen {
  width: 100%;
  height: 100%;
  color: #cdf564;
}

.initial-animate-screen .circle {
  position: absolute;
  /* transform: scale(2); */
  width: 130vw;
  height: 100%;
  text-align: center;
  /* font-size: 1.6em; */
  /* letter-spacing: 4px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  background-color: #3b00e2;
  z-index: 11111;
}

.initial-animate-screen .circle h1 {
  width: 100%;
  letter-spacing: 5px;
  font-size: 6.2vw;
  /* line-height: 15vh; */
}

.initial-animate-screen .circle#one {
  clip-path: circle(11vw);
  z-index: 10;
}

.initial-animate-screen .circle.two {
  clip-path: circle(22vw);
  z-index: 9;
}

.initial-animate-screen .circle.three {
  clip-path: circle(33vw);
  z-index: 8;
}

.initial-animate-screen .circle.four {
  clip-path: circle(44vw);
  z-index: 7;
}

.initial-animate-screen .circle.five {
  clip-path: circle(55vw);
  z-index: 6;
}

.display-none {
  display: none;
}

@media (orientation: portrait) {
  .initial-animate-screen .circle {
    width: 130vh;
  }

  .initial-animate-screen .circle h1 {
    font-size: 5.41vh;
  }

  .initial-animate-screen .circle#one {
    clip-path: circle(11vh);
  }

  .initial-animate-screen .circle.two {
    clip-path: circle(22vh);
  }

  .initial-animate-screen .circle.three {
    clip-path: circle(33vh);
  }

  .initial-animate-screen .circle.four {
    clip-path: circle(44vh);
  }

  .initial-animate-screen .circle.five {
    clip-path: circle(55vh);
  }
}

.top-artist-cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  color: #cdf564;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.top-artist-cont .top-artist-card {
  width: 60vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transform-style: preserve-3d;
  transform: perspective(400px);
}

.top-artist-cont .top-artist-card h2 {
  font-size: 1.5em;
  font-weight: 600;
  padding: 0.5em;
  /* padding-left: auto; */
}

.top-artist-cont .top-artist-card p {
  font-size: 0.8em;
  padding: 0.5em;
}

.top-artist-cont .top-artist-card .text div {
  overflow: hidden;
}

.top-artist-cont .top-artist-card .artist-name {
  color: white;
  transform: translateZ(75px);
  padding: 0.5em;
}

.top-artist-cont .top-artist-card .artist-img {
  transform: translateZ(50px);
  width: 25%;
  margin-right: 1em;
  overflow: hidden;
}

.top-artist-cont .top-artist-card .artist-img img {
  width: 100%;
}

.album-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 130%;
  height: 100%;
  background-color: #3b00e2;
  color: white;
  z-index: 10;
}

.album-bg p {
  font-size: 3em;
  opacity: 0.2;
  font-weight: 900;
}

@media (orientation: portrait) {
  .top-artist-cont .top-artist-card {
    flex-direction: column;
    width: 80vw;
    text-align: center;
  }
  .top-artist-cont .top-artist-card .text {
    width: 100%;
  }

  .top-artist-cont .top-artist-card .artist-img {
    width: 75%;
    margin-right: 0;
  }
}

.pagination {
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 5%;
  right: 5%;
  z-index: 1112;
  flex-direction: column;
  font-size: 1em;
  padding: 0.5em;
  text-align: center;
}

.prev,
.next {
  color: white;
}
