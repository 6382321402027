* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.wrap {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: #3b00e2;
}

.login .spotify-icon {
  color: white;
  margin: 0.5em;
  /* font-size: 2em; */
}

.login .logo h1 {
  font-size: 1em;
  display: inline-block;
  /* height: 100%; */
  margin: 0.5em;
}

.logo {
  color: white;
  display: flex;
  align-items: center;
  overflow: visible;
  justify-content: center;
  /* width: 60vw; */
  font-size: 3em;
  /* margin: 1em; */
}

.login a {
  color: #3b00e2;
  background-color: #cdf564;
  margin: 1em;
  padding: 0.5em;
  font-size: 1em;
  text-decoration: none;
  font-weight: 800;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.7s ease;
}

.login a:hover {
  background: white;
}

.login .tag-line {
  margin: 1em;
  color: #cdf564;
  font-size: 1.5em;
}

.author-credits{
  font-size: 12px;
  display: block;
  color: white;
}

@media (orientation: portrait) {
  .logo {
    width: 70vw;
    font-size: 1.5em;
  }

  .login .tag-line {
    font-size: 0.8em;
  }

  .login a {
    font-size: 0.8em;
  }
}
