* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
  /* font-size: 10px; */
}

#root {
  overflow: hidden;
  user-select: none;
}
