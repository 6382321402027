.TopSong {
  width: 100vw;
  height: 100vh;
  background-color: #3b00e2;
  text-align: center;
}

.TopSong .circle h1 {
  width: 130%;
  font-size: 8vw;
}

.TopSong .top-song-cont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TopSong .top-song-cont .top-song-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #cdf564;
  width: 60vw;
  transform-style: preserve-3d;
}

.TopSong .top-song-cont .top-song-card .top-song-img {
  width: 25%;
  transform: translateZ(50px);
}

.TopSong .top-song-cont .top-song-card .top-song-img img {
  width: 100%;
  margin-right: 1em;
  /* object-fit: contain; */
}

.TopSong .top-song-cont .top-song-card .text h2 {
  font-size: 1.5em;
  font-weight: 600;
  padding: 0.5em;
}

.TopSong .top-song-cont .top-song-card .text p {
  font-size: 0.8em;
  padding: 0.5em;
}

.TopSong .top-song-cont .top-song-card div {
  overflow: hidden;
}

.TopSong .top-song-cont .top-song-card .text .song-name {
  color: white;
  padding: 0.5em;
  transform: translateZ(75px);
  /* font-size: 1.8em; */
}

@media (orientation: portrait) {
  .TopSong .top-song-cont .top-song-card {
    flex-direction: column;
    width: 80vw;
    text-align: center;
  }
  .top-song-cont .top-song-card .text {
    width: 100%;
  }

  .TopSong .top-song-cont .top-song-card .top-song-img {
    width: 75%;
  }
}
